import styles from "./styles.module.css";

const BackgroundOverlay = (props) => {
  return (
    <div
      className={`${styles.overlayContainer} ${
        props.className || styles.noClassOverlay
      }`}
      style={{
        backgroundColor: `${props.color ? props.color : "rgb(0,0,0,0.6)"}`,
      }}
    ></div>
  );
};

export default BackgroundOverlay;
