const LineDivider = (props) => {
  const style = {
    borderTop: `4px solid ${props.color ? props.color : "#661514"}`,
    opacity: "1",
    width: "100%",
    maxWidth: props.width ? props.width : "80px",
    margin: props.center
      ? "20px auto"
      : props.right
      ? "20px 0 20px auto"
      : props.margin
      ? props.margin
      : "20px 0",
  };
  return <hr style={style} />;
};

export default LineDivider;
